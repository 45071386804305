import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { dopContract } from "../utils/contractHelpers";

export const DopClaim = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.dop;
  const contract = dopContract(tokenAddress, web3);
  const IsClaimed = useCallback(
    async (account) => {
      var approved;
      approved = await contract.methods.isClaimed(account).call();
      return approved;
    },
    [account, contract]
  );

  return { IsClaimed: IsClaimed };
};

export default DopClaim;
