import React, { useState } from "react";
import "./kycmain.scss";
import Header from "../landing/Header/Header";
import { useHistory } from "react-router-dom";

const Kycmain = () => {
  const [us, setUs] = useState(false);
  const history = useHistory();

  const SaveUS = () => {
    localStorage.setItem("usStatus", us);
    if (us) {
      history.push("/kyc");
    } else {
        history.push("/kyctwo")
    }
  };

  return (
    <>
      <Header />
      <section className="kycmain">
        <div className="innerkycmain">
          <div className="innerkyc">
            <h4 className="kychead">
              Please note that you are going to go through the KYC process
            </h4>
            <div class="checkboxmain">
              <div class="content">
                <label class="checkBox">
                  <input
                    type="checkbox"
                    id="ch"
                    checked={us===false}
                    onChange={() => {
                      setUs(!us);
                    }}
                  />
                  <div class="transition"></div>
                </label>
              </div>
              <p class="maincheckboxpara">I am not a US citizen</p>
            </div>
            <div class="checkboxmain">
              <div class="content">
                <label class="checkBox">
                  <input
                    type="checkbox"
                    id="ch"
                    checked={us}
                    onChange={() => {
                      setUs(!us);
                    }}
                  />
                  <div class="transition"></div>
                </label>
              </div>
              <p class="maincheckboxpara">
                I hereby declare that I am a US citizen and will require to
                provide an Accredited Investor Certificate.
              </p>
            </div>
            <button
              onClick={() => SaveUS()}
              className="proceedbtn"
            >
              proceed
            </button>
          </div>
        </div>
        <p className="lastpara">
          Need help? Reach out to us on our{" "}
          <a className="lastparalink" href="">
            telegram community
          </a>
        </p>
      </section>
    </>
  );
};

export default Kycmain;
