import React, { useState } from "react";
import { useSelector } from "react-redux";

function Loader({ text }) {
  const theme = useSelector((state) => state.UserReducer.theme);

  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100 mainLoader"
          style={{
            zIndex: 1100000000000,
            // marginTop: -200,
            height: "100%",
            marginLeft: 0,
            background: "rgba(0, 0, 0, 0.90)",
            backdropFilter: "blur(4px)",
            top: "0px",
            left: "0px",
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div
              className="d-flex flex-wrap align-items-center justify-content-center"
              style={{ flexDirection: "column" }}
            >
              <img width={120} className="mt-n4" src="\mainloader.svg" alt="" />
              <p
                className="text-center noora"
                style={{ color: "#fff", fontSize: 20 }}
              >
                {text}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Loader;
