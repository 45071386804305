import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { dopContract } from "../utils/contractHelpers";
import axios from "axios";

export const ClaimDops = () => {
  const { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  const stakingAddress = environment.dop;
  const contract = dopContract(stakingAddress, web3);

  const Mint = useCallback(
    async ( 
      quantity,
      account,
      proof,
      isKycRequired,
      v,
      r,
      s,
      callback
    ) => {
      console.log(
        "quantity payload",
        account,
        quantity,
        proof,
        isKycRequired,
        v,
        r,
        s
      );
      try {
        let gasPrice = await web3.eth.getGasPrice();
        gasPrice = parseInt(gasPrice) + 3000000000;
        const gas = await contract.methods
          .claimDop(
            quantity,
            proof,
            isKycRequired,
            v,
            r,
            s
          )
          .estimateGas({ from: account });
        const claimed = await contract.methods
          .claimDop(
            quantity,
            proof,
            isKycRequired,
            v,
            r,
            s
          )
          .send({ from: account, gas: gas, gasPrice: gasPrice })
          .on('transactionHash', (tx) => {
            console.log("tx:::" , tx)
            callback()
             return tx 
            });
        // return true;
      } catch (e) {
        return e;
      }
    },
    [contract]
  );

  return { Claim: Mint };
};

export default ClaimDops;
