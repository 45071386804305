import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { dopContract } from "../utils/contractHelpers";
import axios from "axios";

export const ClaimDopsNFT = () => {
  const { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  const stakingAddress = environment.dop;
  const contract = dopContract(stakingAddress, web3);

  const Mint = useCallback(
    async (quantity, account, proof, ids) => {
      try {
        const gas = await contract.methods
          .claimDopNFT(proof, ids, quantity)
          .estimateGas({
            from: account,
          });

        const claimed = await contract.methods
          .claimDopNFT(proof, ids, quantity)
          .send({
            from: account,
            gas: gas,
          })
          .on("transactionHash", (tx) => {
            return tx.transactionHash;
          })
          .catch((error) => {
            throw error;
          });
        return claimed;
      } catch (e) {
        throw e;
      }
    },
    [contract]
  );

  return { ClaimNFT: Mint };
};

export default ClaimDopsNFT;
