import React, { useState } from "react";
import "./header.scss";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { Dropdown } from "react-bootstrap";

const Header = () => {
  const { login, logout } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { account } = useWeb3React();

  const Logout = async () => {
    let locaWallet = localStorage.getItem("connectorId");
    await logout(locaWallet);
    localStorage.clear();
    history.push("/");
    window.location.reload()
  };

  const isHomePage = location.pathname === "/";

  const [isOpen, setIsOpen] = useState(true);

  const style = {
    background: isOpen && "transparent",
    color: isOpen && "#FFF"
  };
  return (
    <section className="mainheader">
      <div className="custom-container">
        <div className="innerheader">
          <a
            onClick={() => {
              window?.location?.reload();
            }}
          >
            <img src="\logo.svg" alt="logoimg" className="logoimg" />
          </a>
          <div className="twice-btn">
          
            {account ? (
              <div
                onClick={() => Logout()}
                className="disconnectbtn cursor-pointer"
              >
                Disconnect
              </div>
            ) : (
              <></>
            )}
              <Dropdown onToggle={(isOpen) => setIsOpen(isOpen)}
            >
              <Dropdown.Toggle className="newbtn" id="dropdown-basic" style={style}>
                {
                  isOpen ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path d="M18.4166 2.63251H7.58329C4.33329 2.63251 2.16663 4.79917 2.16663 8.04917V14.5492C2.16663 17.7992 4.33329 19.9658 7.58329 19.9658V22.2733C7.58329 23.14 8.54746 23.66 9.26246 23.1725L14.0833 19.9658H18.4166C21.6666 19.9658 23.8333 17.7992 23.8333 14.5492V8.04917C23.8333 4.79917 21.6666 2.63251 18.4166 2.63251ZM13 15.8167C12.545 15.8167 12.1875 15.4483 12.1875 15.0042C12.1875 14.56 12.545 14.1917 13 14.1917C13.455 14.1917 13.8125 14.56 13.8125 15.0042C13.8125 15.4483 13.455 15.8167 13 15.8167ZM14.365 11.3208C13.9425 11.6025 13.8125 11.7867 13.8125 12.09V12.3175C13.8125 12.7617 13.4441 13.13 13 13.13C12.5558 13.13 12.1875 12.7617 12.1875 12.3175V12.09C12.1875 10.8333 13.1083 10.2158 13.455 9.97751C13.8558 9.70667 13.9858 9.52251 13.9858 9.24084C13.9858 8.69917 13.5416 8.25501 13 8.25501C12.4583 8.25501 12.0141 8.69917 12.0141 9.24084C12.0141 9.68501 11.6458 10.0533 11.2016 10.0533C10.7575 10.0533 10.3891 9.68501 10.3891 9.24084C10.3891 7.80001 11.5591 6.63001 13 6.63001C14.4408 6.63001 15.6108 7.80001 15.6108 9.24084C15.6108 10.4758 14.7008 11.0933 14.365 11.3208Z" fill="white" />
                </svg>
                
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path d="M18.4167 2.63251H7.58334C4.33334 2.63251 2.16667 4.79917 2.16667 8.04917V14.5492C2.16667 17.7992 4.33334 19.9658 7.58334 19.9658V22.2733C7.58334 23.14 8.5475 23.66 9.2625 23.1725L14.0833 19.9658H18.4167C21.6667 19.9658 23.8333 17.7992 23.8333 14.5492V8.04917C23.8333 4.79917 21.6667 2.63251 18.4167 2.63251ZM13 15.8167C12.545 15.8167 12.1875 15.4483 12.1875 15.0042C12.1875 14.56 12.545 14.1917 13 14.1917C13.455 14.1917 13.8125 14.56 13.8125 15.0042C13.8125 15.4483 13.455 15.8167 13 15.8167ZM14.365 11.3208C13.9425 11.6025 13.8125 11.7867 13.8125 12.09V12.3175C13.8125 12.7617 13.4442 13.13 13 13.13C12.5558 13.13 12.1875 12.7617 12.1875 12.3175V12.09C12.1875 10.8333 13.1083 10.2158 13.455 9.97751C13.8558 9.70667 13.9858 9.52251 13.9858 9.24084C13.9858 8.69917 13.5417 8.25501 13 8.25501C12.4583 8.25501 12.0142 8.69917 12.0142 9.24084C12.0142 9.68501 11.6458 10.0533 11.2017 10.0533C10.7575 10.0533 10.3892 9.68501 10.3892 9.24084C10.3892 7.80001 11.5592 6.63001 13 6.63001C14.4408 6.63001 15.6108 7.80001 15.6108 9.24084C15.6108 10.4758 14.7008 11.0933 14.365 11.3208Z" fill="black" />
                  </svg>
                }

                KYC HELP?
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="kycmaindrop">
                  <h6 className="kycdrophead">KYC HELP</h6>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      1.
                    </span>
                    <p className="kyclistpara">If you have previously connected your wallet to the website: Hard refresh the website, end all your current wallet connection, then reconnect your wallet to the claim site.</p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      2.
                    </span>
                    <p className="kyclistpara">Connect the wallet you used when you purchased DOP in the Private Sale.</p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      3.
                    </span>
                    <p className="kyclistpara">Click on Start KYC in the Summary pane on the right.</p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      4.
                    </span>
                    <p className="kyclistpara">Go through the KYC process on the zkMe app.</p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      5.
                    </span>
                    <p className="kyclistpara">If you successfully complete the KYC process, the KYC button on the DOP website will change to Claim, allowing you to claim your tokens.</p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      6.
                    </span>
                    <p className="kyclistpara">In case you encounter any issues with the DOP KYC system, please use this alternative link to complete the KYC process through Sumsub, our web2 KYC provider: <a href="https://in.sumsub.com/websdk/p/uni_uiQR7U3PDWx955FM" target="_blank" className="linkbold">https://in.sumsub.com/websdk/p/uni_uiQR7U3PDWx955FM</a></p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      7.
                    </span>
                    <p className="kyclistpara">If the Sumsub KYC process completes successfully, wait 24 hours and then try again to claim at <a href="https://claim.dop.org/" target="_blank" className="linkbold">claim.dop.org</a>. You should now be able to claim.</p>
                  </div>
                  <div className="kyclistmain">
                    <span className="serialkyc">
                      8.
                    </span>
                    <p className="kyclistpara">If you still face difficulties, reach out to <a href="https://kyc@dop.org" target="_blank" className="linkbold">kyc@dop.org</a>.</p>
                  </div>
                  <div className="importantnotemain">
                    <h6 className="importanthead">Important Note</h6>
                    <p className="importantpara">
                    Please be aware that if you reside in a country currently under sanctions, you will not be permitted to claim the funds or tokens, in accordance with the information provided by you during the KYC process and pursuant to the User Agreement and Terms and Conditions accepted by you before your purchase, as required by applicable regulations and procedures governing the sale.
                    </p>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
