import React, { useRef, useState, useEffect } from "react";
import "./kyc.scss";
import Header from "../landing/Header/Header";
import { Link, useHistory } from "react-router-dom";
import { API_URL } from "../../utils/ApiUrl";
import axios from "axios";
import Loader from "../../hooks/loader";
import { useWeb3React } from "@web3-react/core";
import { ZkMeWidget, verifyWithZkMeServices } from "@zkmelabs/widget";
import { setupNetwork } from "../../utils/wallet";

const Kyc = () => {
  //imge upload funcion start
  const history = useHistory();
  const fileInputRef = useRef(null);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [kyc, setKyc] = useState(false);
  const [kycCompleted, setKycCompleted] = useState(false);
  const [ticketImg, setTicketImg] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isKycVer, setIsKycVer] = useState(true);
  const [profile, setProfile] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log("event", file);
    setTicketImg(file);
    imgeUploadDetails(file);
  };
  const uplaodImgeApi = async () => {
    let token = localStorage.getItem("dopToken");
    setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}users/profile`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        setLoader(false);
      })
      .catch(function (err) {
        console.log("err", err);
        setLoader(false);
      });
  };

  //imge upload funcion end

  // kyc funnction function start

  const [zkMeWidget, setZkMeWidget] = useState(null);
  const [token, setToken] = useState(null);
  const [rend, setRend] = useState(false);
  const { account, chainId } = useWeb3React();

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = () => {
    var config = {
      method: "post",
      url: "https://nest-api.zk.me/api/token/get",
      data: {
        apiKey: "edde27f3.f169118cf2cb787c79179cb76e114a7b",
        appId: "M2024032712011522654927764408546",
        apiModePermission: 0,
        lv: 1,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("res !!!", response?.data?.data?.accessToken);
        setToken(response?.data?.data?.accessToken);
      })
      .catch(function (error) {
        console.log("error !!!", error);
      });
  };

  useEffect(() => {
    if (token && account) {
      SetUPZkME();
    }
  }, [token, account]);

  // console.log("ooxoxox", account)

  const SetUPZkME = async () => {
    var userConnectedAddress = account;
    const provider = {
      async getAccessToken() {
        // Request a new token from your backend service and return it to the widget.
        // For the access token, see https://docs.zk.me/zkme-dochub/zkkyc-compliance-suite/integration-guide/widget-sdk-integration#usage-example
        return token;
      },

      async getUserAccounts() {
        // If your project is a Dapp,
        // you need to return the user's connected wallet address.
        if (!userConnectedAddress) {
          userConnectedAddress = await account;
        }
        return [userConnectedAddress];

        // If not,
        // you should return the user's e-mail address, phone number or any other unique identifier.
        //
        // return ['email address']
        // or
        // return ['phone number']
        // or
        // return ['unique identifier']
      },

      // According to which blockchain your project is integrated with,
      // choose and implement the corresponding methods as shown below.
      // If you are integrating Anti-Sybil(MeID) , you don't need to implement them.

      // EVM
      async delegateTransaction(tx) {
        // try {
        const provider = window.ethereum;
        const accounts = await provider // Or window.ethereum if you don't support EIP-6963.
          .request({ method: "eth_requestAccounts" });
        let myAccount = accounts?.find((i) => {
          return i === account?.toLowerCase();
        });
        console.log("myAccount", myAccount);
        let txResponse = null;
        provider // Or window.ethereum if you don't support EIP-6963.
          .request({
            method: "eth_sendTransaction",
            // The following sends an EIP-1559 transaction. Legacy transactions are also supported.
            // params: [tx],
            params: [
              {
                from: myAccount,
                to: tx.to,
                data: tx.data,
                gas: tx.gasLimit,
                gasPrice: tx.maxFeePerGas,
              },
            ],
          })
          .then((txHash) => {
            txResponse = txHash;
            console.log(txHash);
            return txHash;
          })
          .catch((error) => console.error(error));

        // if (txResponse) {
        //   console.log("xxxoxox", txResponse);
        //   return txResponse;
        // }
        // const web3 = new Web3(
        //   "https://polygon-mainnet.infura.io/v3/4748414432494c9b91c15f6188369b22"
        // );
        // console.log("txtxtx", tx, myAccount);
        // console.log("web3", web3);

        // const txResponse = await web3.eth.sendTransaction({
        // from: tx.from,
        // to: tx.to,
        // data: tx.data,
        // gas: tx.gasLimit,
        // gasPrice: tx.maxFeePerGas,
        // });
        // return txResponse.hash;
        // } catch (err) {
        //   console.log("error:::", err);
        // }
      },
      // Cosmos
      // async delegateCosmosTransaction(tx) {
      //   const txResponse = await signingCosmWasmClient.execute(
      //     tx.senderAddress,
      //     tx.contractAddress,
      //     tx.msg,
      //     "auto"
      //   );
      //   return txResponse.transactionHash;
      // },
      // // Aptos
      // async delegateAptosTransaction(tx) {
      //   const txResponse = await aptos.signAndSubmitTransaction(tx);
      //   return txResponse.hash;
      // },
      // ...
      // See the Provider interface definition for more details on other chains.
    };

    console.log("!!!!", provider, token);

    const zkMeWidget = new ZkMeWidget(
      "M2024032712011522654927764408546", // This parameter means the same thing as "mchNo"
      "DOP_KYC",
      1,
      provider
      // Optional configurations are detailed in the table below
      //   options
    );

    await setZkMeWidget(zkMeWidget);
    await setRend(!rend);

    async function handleFinished(verifiedAccount) {
      // We recommend that you double-check this by calling
      // the functions mentioned in the "Helper functions" section.
      console.log("verifiedAccountverifiedAccount", verifiedAccount);
      // setKyc(true)
      if (verifiedAccount === userConnectedAddress?.toLowerCase()) {
        let appId = "M2024032712011522654927764408546";
        // zkKYC
        const results = await verifyWithZkMeServices(
          appId,
          userConnectedAddress
        );
        let sgnn = localStorage.getItem("signValue");
        let tok = localStorage.getItem("dopToken");
        let devicee = localStorage.getItem('device')
        if(devicee === 'Safe{Wallet}'){
        if (results) {
          const postData = {
            sign: sgnn,
          };
          axios
            .patch(`${API_URL}users/update-kyc/safe`, postData, {
              headers: {
                Authorization: `Bearer ${tok}`, // Include your authentication token here
              },
            })
            .then(async (response) => {
              console.log("verify email", response?.data?.data);
              setKyc(true);
            })
            .catch((error) => {
              console.log("error", error?.response?.data?.message);
            });
          // Prompts the user that zkKYC/MeID verification has been completed
        }
      }
        else{
          if (results) {
            const postData = {
              sign: sgnn,
            };
            axios
              .patch(`${API_URL}users/update-kyc`, postData, {
                headers: {
                  Authorization: `Bearer ${tok}`, // Include your authentication token here
                },
              })
              .then(async (response) => {
                console.log("verify email", response?.data?.data);
                setKyc(true);
              })
              .catch((error) => {
                console.log("error", error?.response?.data?.message);
              });
            // Prompts the user that zkKYC/MeID verification has been completed
          }
        }
      }
    }

    zkMeWidget.on("finished", handleFinished);
  };

  const launchZkME = () => {
    zkMeWidget.launch();
  };

  const chainSwitch = async () => {
    let b = await setupNetwork(137);
  };
  // kyc funnction function end

  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //status of imge
  const imgeUploadDetails = async (vv) => {
    const base64 = await convertBase645(vv);
    const formData = new FormData();
    formData.append("zkmeId", account.toLocaleLowerCase());
    formData.append("document", vv);

    const data = { zkmeId: account?.toLocaleLowerCase(), document: base64 };
    console.log("form data: ", formData);
    let token = localStorage.getItem("dopToken");
    setLoader(true);
    var config = {
      method: "post",
      url: `${API_URL}kycs`,
      data: formData,

      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data",
      },

      // data: {
      //   'zkmeId':account.toLocaleLowerCase(),
      //   document:vv
      // },
    };
    await axios(config)
      .then(function (res) {
        console.log(res, "imgeeeee");
        setDocumentUploaded(true);
        setLoader(false);
      })
      .catch(function (err) {
        console.log("err", err);
        setLoader(false);
      });
  };

  const getProfile = async () => {
    let token = localStorage.getItem("dopToken");
    setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}users/profile`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        let resss = res?.data.data;
        resss.documentSubmitted = res?.data?.documentSubmitted;
        // let date = new Date(resss?.rewardCreatedAt).toLocaleDateString();
        // setAirdropAmount(resss?.airdropAmount);
        setProfile(resss);
        if (resss?.kycRequired === false) {
          setIsKycVer("approved");
        } else {
          setIsKycVer(resss?.kycStatus);
        }
        // setEarnOn(date);
        setLoader(false);
      })
      .catch(function (err) {
        if (err?.response?.data?.statusCode === 401) {
          refreshTokeen();
        } else {
          setLoader(false);
        }
      });
  };
  const refreshTokeen = async () => {
    let retoken = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: `${API_URL}auth/refresh-token`,
      data: {
        refreshToken: retoken,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;
        localStorage?.setItem("dopToken", resData?.accessToken);
        localStorage?.setItem("refreshToken", resData?.refreshToken);
        // setLoader(false)
        getProfile();
        // dopTransaction();
        // NftTransaction();
      })
      .catch(function (err) {
        // diconnect()
        localStorage.clear();
        history.push("/");
        setLoader(false);
        throw err;
      });
  };
  useEffect(() => {
    if (account) {
      getProfile();
    }
  }, [account]);

  useEffect(() => {
    if (profile?.kycStatus === "approved") {
      history.push("/claimall");
    } else {
      if (profile?.documentSubmitted) {
        if (profile?.kycStatus === "pending") {
          setDocumentUploaded(true);
          setKyc(true);
        } else {
          setDocumentUploaded(true);
        }
      }
    }
  }, [profile]);

  console.log("osososo", chainId)

  return (
    <>
      {loader && <Loader />}
      <Header />
      <section className="kyc">
        <div className="kyctimeline">
          <div className="whitebox timelineinner">1</div>
          <span className="timelineline whiteline"></span>
          <div
            className={
              documentUploaded ? "timelineinner whitebox" : "timelineinner"
            }
          >
            2
          </div>
          <span
            className={
              documentUploaded ? "timelineline whiteline" : "timelineline"
            }
          ></span>
          <div className={kyc ? "timelineinner whitebox" : "timelineinner"}>
            3
          </div>
        </div>
        {documentUploaded ? (
          <>
            {kyc ? (
              <>
                {kycCompleted ? (
                  <>
                    {/* <div className="innerkycmain">
                                                        <div className="innerkyc">
                                                            <h4 className="kychead green">KYC Completed</h4>
                                                            <p className="kycpara">You can now proceed with your claim</p>
                                                            <button className="proceedbtn">
                                                            Start claim
                                                            </button>
                                                        </div>
                                                    </div> */}
                    <div className="innerkycmain">
                      <div className="innerkyc">
                        <h4 className="kychead red">KYC Failed</h4>
                        <p className="kycpara">
                          We were unable to verify your details. Please start
                          the process again.
                        </p>
                        <Link to="/kycmain" className="proceedbtn">
                          retry kyc process
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="innerkycmain">
                      <div className="innerkyc">
                        <h4 className="kychead">
                          We are reviewing your details
                        </h4>
                        <p className="kycpara" style={{ marginBottom: "0px" }}>
                          We are currently reviewing your details and will let
                          you know once the review process is completed.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="lastkyctexts">
                      <p className="contactpara">
                        We will contact you via this email:
                      </p>
                      <span
                        onClick={() => {
                          setKycCompleted(true);
                        }}
                        className="emailpara"
                      >
                        support@dop.org
                      </span>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="innerkycmain">
                <div className="innerkyc">
                  <h4 className="kychead">Zero Knowledge KYC</h4>
                  <p className="kycpara">
                    You will need an ID or any other identification document
                    along with a picture of your face
                  </p>
                  {/* {chainId == 137 ? ( */}
                    <>
                      {zkMeWidget && (
                        <button
                          onClick={() => {
                            // setKyc(true);
                            launchZkME();
                          }}
                          className="proceedbtn"
                        >
                          Start kyc
                        </button>
                      )}
                    </>
                  {/* ) : (
                    <button
                      onClick={() => chainSwitch()}
                      className="proceedbtn"
                    >
                      Switch to Polygon Network
                    </button>
                  )} */}

                  {/* <button onClick={() => {
                                              
                                            }} className="proceedbtn">
                                                Start kyc
                                            </button> */}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="innerkycmain">
              <div className="innerkyc">
                <h4 className="kychead">Upload Documents</h4>
                <p className="kycpara">
                  Please upload the Accredited Investor Certificate.{" "}
                </p>

                <div className="wrapper">
                  <label
                    htmlFor="upload"
                    // onClick={(e)=>handleImageUpload(e)}
                    className="proceedbtn d-flex align-items-center"
                  >
                    {/* <img src='\assets\paperclip-2.png' alt='img' className='img-fluid' />
                            <p className='gray'>Select File</p> */}
                    {/* <button id='upload'
                                     className="proceedbtn"> */}
                    UPLOAD DOCUMENT
                    {/* </button> */}
                  </label>
                  {/* <input type='file' id='upload' placeholder='hhhhhhhhhh' className='d-none' /> */}
                  <input
                    type="file"
                    id="upload"
                    ref={fileInputRef}
                    onChange={(e) => handleImageUpload(e)}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="lasttexts">
              <p className="kyclastpara">
                In case you dont have one, one can be provided to you in the
                following website
              </p>
              <a
                href="https://www.verifyinvestor.com"
                target="_blank"
                className="kyclink"
              >
                https://www.verifyinvestor.com
              </a>
            </div>
          </>
        )}

        <p className="lastpara">
          Need help? Reach out to us on our{" "}
          <a className="lastparalink" href="">
            telegram community
          </a>
        </p>
      </section>
    </>
  );
};

export default Kyc;
