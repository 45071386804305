import React, { useState, useEffect } from "react";
import "./kyc.scss";
import axios from "axios";
import "@zkmelabs/widget/dist/style.css";
import Header from "../landing/Header/Header";
import { ZkMeWidget, verifyWithZkMeServices } from "@zkmelabs/widget";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../hooks/useAuth";
import { API_URL } from "../../utils/ApiUrl";
import Web3 from "web3";
import { setupNetwork } from "../../utils/wallet";
import { Accordion, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from "../../hooks/loader";

const Kyctwo = () => {
  const { login, logout } = useAuth();
  const history = useHistory();
  const [kyc, setKyc] = useState(false);
  const [kycCompleted, setKycCompleted] = useState(false);
  const [zkMeWidget, setZkMeWidget] = useState(null);
  const [token, setToken] = useState(null);
  const [rend, setRend] = useState(false);
  const { account, chainId } = useWeb3React();
  const [email, setEmail] = useState(null);
  const [emailStatus, setEmailStatus] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isKycVer, setIsKycVer] = useState(true);
  let timeoutId;

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    let timeoutId;

    // Check if account is not available after 3 seconds
    timeoutId = setTimeout(() => {
      if (!account) {
        history.push("/");
      }
    }, 1500); // 1.5 seconds

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [account]);

  const refreshTokeen = async () => {
    let retoken = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: `${API_URL}auth/refresh-token`,
      data: {
        refreshToken: retoken,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;
        localStorage?.setItem("dopToken", resData?.accessToken);
        localStorage?.setItem("refreshToken", resData?.refreshToken);
        // setLoader(false)
        getProfile();
        // dopTransaction();
        // NftTransaction();
      })
      .catch(function (err) {
        // diconnect()
        localStorage.clear();
        history.push("/");
        setLoader(false);
        throw err;
      });
  };

  const getAccessToken = () => {
    var config = {
      method: "post",
      url: "https://nest-api.zk.me/api/token/get",
      data: {
        apiKey: "edde27f3.f169118cf2cb787c79179cb76e114a7b",
        appId: "M2024032712011522654927764408546",
        apiModePermission: 0,
        lv: 1,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("res !!!", response?.data?.data?.accessToken);
        setToken(response?.data?.data?.accessToken);
      })
      .catch(function (error) {
        console.log("error !!!", error);
      });
  };

  const trustWalletSignUp = async () => {
    if (account) {
      await logout("walletconnect1");
    } else {
      login("walletconnect1");
      localStorage.setItem("connectorId", "walletconnect1");
      localStorage.setItem("flag", "true");
    }
  };

  useEffect(() => {
    if (account) {
      getProfile();
    }
  }, [account]);

  const getProfile = async () => {
    let token = localStorage.getItem("dopToken");
    setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}users/profile`,

      headers: {
        Authorization: "Bearer " + token,
      },
      data: {},
    };
    await axios(config)
      .then(function (res) {
        let resss = res?.data.data;
        resss.documentSubmitted = res?.data?.documentSubmitted;
        // let date = new Date(resss?.rewardCreatedAt).toLocaleDateString();
        // setAirdropAmount(resss?.airdropAmount);
        setProfile(resss);
        if (resss?.kycRequired === false) {
          setIsKycVer("approved");
        } else {
          setIsKycVer(resss?.kycStatus);
        }
        // setEarnOn(date);
        setLoader(false);
      })
      .catch(function (err) {
        if (err?.response?.data?.statusCode === 401) {
          refreshTokeen();
        } else {
          setLoader(false);
        }
      });
  };

  useEffect(() => {
    if (token && account) {
      SetUPZkME();
    }
  }, [token, account]);

  // console.log("ooxoxox", account)

  const SetUPZkME = async () => {
    var userConnectedAddress = account;
    const provider = {
      async getAccessToken() {
        // Request a new token from your backend service and return it to the widget.
        // For the access token, see https://docs.zk.me/zkme-dochub/zkkyc-compliance-suite/integration-guide/widget-sdk-integration#usage-example
        return token;
      },

      async getUserAccounts() {
        // If your project is a Dapp,
        // you need to return the user's connected wallet address.
        if (!userConnectedAddress) {
          userConnectedAddress = await account;
        }
        return [userConnectedAddress];

        // If not,
        // you should return the user's e-mail address, phone number or any other unique identifier.
        //
        // return ['email address']
        // or
        // return ['phone number']
        // or
        // return ['unique identifier']
      },

      // According to which blockchain your project is integrated with,
      // choose and implement the corresponding methods as shown below.
      // If you are integrating Anti-Sybil(MeID) , you don't need to implement them.

      // EVM
      async delegateTransaction(tx) {
        // try {
        const provider = window.ethereum;
        const accounts = await provider // Or window.ethereum if you don't support EIP-6963.
          .request({ method: "eth_requestAccounts" });
        let myAccount = accounts?.find((i) => {
          return i === account?.toLowerCase();
        });
        console.log("myAccount", myAccount);
        let txResponse = null;
        provider // Or window.ethereum if you don't support EIP-6963.
          .request({
            method: "eth_sendTransaction",
            // The following sends an EIP-1559 transaction. Legacy transactions are also supported.
            // params: [tx],
            params: [
              {
                from: myAccount,
                to: tx.to,
                data: tx.data,
                gas: tx.gasLimit,
                gasPrice: tx.maxFeePerGas,
              },
            ],
          })
          .then((txHash) => {
            txResponse = txHash;
            console.log(txHash);
            return txHash;
          })
          .catch((error) => console.error(error));

        // if (txResponse) {
        //   console.log("xxxoxox", txResponse);
        //   return txResponse;
        // }
        // const web3 = new Web3(
        //   "https://polygon-mainnet.infura.io/v3/4748414432494c9b91c15f6188369b22"
        // );
        // console.log("txtxtx", tx, myAccount);
        // console.log("web3", web3);

        // const txResponse = await web3.eth.sendTransaction({
        // from: tx.from,
        // to: tx.to,
        // data: tx.data,
        // gas: tx.gasLimit,
        // gasPrice: tx.maxFeePerGas,
        // });
        // return txResponse.hash;
        // } catch (err) {
        //   console.log("error:::", err);
        // }
      },
      // Cosmos
      // async delegateCosmosTransaction(tx) {
      //   const txResponse = await signingCosmWasmClient.execute(
      //     tx.senderAddress,
      //     tx.contractAddress,
      //     tx.msg,
      //     "auto"
      //   );
      //   return txResponse.transactionHash;
      // },
      // // Aptos
      // async delegateAptosTransaction(tx) {
      //   const txResponse = await aptos.signAndSubmitTransaction(tx);
      //   return txResponse.hash;
      // },
      // ...
      // See the Provider interface definition for more details on other chains.
    };

    console.log("!!!!", provider, token);

    const zkMeWidget = new ZkMeWidget(
      "M2024032712011522654927764408546", // This parameter means the same thing as "mchNo"
      "DOP_KYC",
      1,
      provider
      // Optional configurations are detailed in the table below
      //   options
    );

    await setZkMeWidget(zkMeWidget);
    await setRend(!rend);

    async function handleFinished(verifiedAccount) {
      // We recommend that you double-check this by calling
      // the functions mentioned in the "Helper functions" section.
      console.log(
        "verifiedAccountverifiedAccount",
        verifiedAccount,
        userConnectedAddress?.toLowerCase(),
        userConnectedAddress?.toLowerCase() === verifiedAccount
      );
      if (verifiedAccount === userConnectedAddress?.toLowerCase()) {
        let appId = "M2024032712011522654927764408546";
        // zkKYC
        const results = await verifyWithZkMeServices(
          appId,
          userConnectedAddress
        );

        console.log("results", results);
        let sgnn = localStorage.getItem("signValue");
        let tok = localStorage.getItem("dopToken");
        let devicee = localStorage.getItem('device')
        if(devicee === 'Safe{Wallet}'){
          if (results) {
            const postData = {
              sign: sgnn,
            };
            axios
              .patch(`${API_URL}users/update-kyc/safe`, postData, {
                headers: {
                  Authorization: `Bearer ${tok}`, // Include your authentication token here
                },
              })
              .then(async (response) => {
                console.log("verify email", response?.data?.data);
                history.push("/claimall");
                // setKyc(true);
                // history.push('')
              })
              .catch((error) => {
                history.push("/claimall");
                console.log("error", error);
              });
            // Prompts the user that zkKYC/MeID verification has been completed
          }
        }
          else{
            if (results) {
              const postData = {
                sign: sgnn,
              };
              axios
                .patch(`${API_URL}users/update-kyc`, postData, {
                  headers: {
                    Authorization: `Bearer ${tok}`, // Include your authentication token here
                  },
                })
                .then(async (response) => {
                  console.log("verify email", response?.data?.data);
                  history.push("/claimall");
                  // setKyc(true);
                  // history.push('')
                })
                .catch((error) => {
                  history.push("/claimall");
                  console.log("error", error);
                });
              // Prompts the user that zkKYC/MeID verification has been completed
            }
          }
      }
    }

    zkMeWidget.on("finished", handleFinished);
  };

  const launchZkME = () => {
    zkMeWidget.launch();
  };

  const chainSwitch = async () => {
    let b = await setupNetwork(137);
  };

  useEffect(() => {
    if (profile?.kycStatus === "approved") {
      history.push("/claimall");
    } else if (profile?.kycStatus === "pending") {
      setKyc(true);
    }
  }, [profile]);

  return (
    <>
      {loader && <Loader />}
      <Header />
      <section className="kyc">
        <div className="kyctimeline">
          {/* <div className="whitebox timelineinner">1</div>
          <span className="timelineline whiteline"></span>
          <div className={kyc ? "timelineinner whitebox" : "timelineinner"}>
            2
          </div> */}
        </div>
        {kyc ? (
          <>
            <div className="innerkycmain">
              <div className="innerkyc">
                <h4 className="kychead">We are reviewing your details</h4>
                <p className="kycpara" style={{ marginBottom: "0px" }}>
                  We are currently reviewing your details and will let you know
                  once the review process is completed.This may take
                  approximately 30 Minutes.{" "}
                </p>
              </div>
            </div>
            <div className="lastkyctexts">
              <p className="contactpara">We will contact you via this email:</p>
              <span
                onClick={() => {
                  setKycCompleted(true);
                }}
                className="emailpara"
              >
                support@dop.org
              </span>
            </div>
          </>
        ) : (
          <div className="innerkycmain">
            <div className="innerkyc">
              <h4 className="kychead">Zero Knowledge KYC</h4>
              <p className="kycpara">
                You will need an ID or any other identification document along
                with a picture of your face
              </p>
              {/* {chainId == 137 ? ( */}
              <>
                {zkMeWidget && (
                  <button
                    onClick={() => {
                      // setKyc(true);
                      launchZkME();
                    }}
                    className="proceedbtn"
                  >
                    Start kyc
                  </button>
                )}
              </>
              {/* ) : (
                <button onClick={() => trustWalletSignUp()} className="proceedbtn">
                  Please switch to Polygon Chain From your Wallet to Continue
                </button>
              )
              } */}
            </div>
          </div>
        )}

        <p className="lastpara">
          Need help? Reach out to us on our{" "}
          <a className="lastparalink" href="">
            telegram community
          </a>
        </p>
      </section>
    </>
  );
};

export default Kyctwo;
